@import '~inter-ui/inter.css';

@import './_variables.sass';
// base bulma styles
// @import '~bulma/sass/utilities/initial-variables.sass';
// @import '~bulma/bulma.sass';
// @import '~bulma/sass/base/_all.sass';
@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/base/_all.sass';
@import '~bulma/sass/components/tabs.sass';
@import '~bulma/sass/components/message.sass';
@import '~bulma/sass/components/navbar.sass';
@import '~bulma/sass/layout/_all.sass';
@import '~bulma/sass/form/_all.sass';
@import '~bulma/sass/elements/_all.sass';
@import '~bulma/sass/grid/columns.sass';
// @import './tabs.button.sass';

// font size baseline
html {
  font-size: $body-size * 1.25;
  @media 
    (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) {
      font-size: $body-size;
    }
}

body {
  font-feature-settings: 'tnum';
}

.dropdown-content {
  // max-height: 14em;
  overflow: auto;
}

.is-justified-content-start {
  justify-content: flex-start;
}

.navbar-burger-button {
  background: none;
  border: none;
}

.reset-button {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: inherit;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: inherit;
}

.is-justified-left {
  justify-content: flex-start;
}

.button.is-text {
  text-decoration: none;
}

/* Abort/NA/Skip/Correct/OK */
.has-background-checklistItemValueUnknown {
    background-color: $checklistItemValueUnknown
}
.has-background-checklistItemValueCheck {
    background-color: $checklistItemValueCheck 
}
.has-background-checklistItemValueOk {
    background-color: $checklistItemValueOk
}
.has-background-checklistItemValueCorrect {
    background-color: $checklistItemValueCorrect
}
.has-background-checklistItemValueSkip {
    background-color: $checklistItemValueSkip
}

.has-background-checklistItemValueNa {
    background-color: $checklistItemValueNa
}
.has-background-checklistItemValueAbort{
    background-color: $checklistItemValueAbort
  }

/*  Q/S/H  */
.has-background-severityQuality{
  background-color: $severityQuality}
.has-background-severitySaved{
  background-color: $severitySaved}
.has-background-severityHarm{
  background-color: $severityHarm}

/*  O/D */
.has-background-occurrenceOpen{
  background-color: $occurrenceOpen}
.has-background-occurrenceDefined{
  background-color: $occurrenceDefined}