@import "~bulma/sass/utilities/initial-variables.sass";
$primary: #00a6e5 !default;

$info: #8dd4ff !default;
$success: #8ddb00 !default;
$warning: #ffd300 !default;
$danger: #ff5900 !default;

$light: $white-ter !default;
$dark: $grey-darker !default;

// Invert colors

$primary-invert: findColorInvert($primary) !default;
$info-invert: findColorInvert($primary) !default;
$success-invert: findColorInvert($success) !default;
$warning-invert: findColorInvert($warning) !default;
$danger-invert: findColorInvert($danger) !default;
$light-invert: $dark !default;
$dark-invert: $light !default;

// General colors

$background: $white-ter !default;

$border: $grey-lighter !default;
$border-hover: $grey-light !default;

// Text colors

$text: $grey-dark !default;
$text-invert: findColorInvert($text) !default;
$text-light: $grey !default;
$text-strong: $grey-darker !default;

// Code colors

$code: $red !default;
$code-background: $background !default;

$pre: $text !default;
$pre-background: $background !default;

// Link colors

$link: $info !default;
$link-invert: $info-invert !default;
$link-visited: $purple !default;

$link-hover: $grey-darker !default;
$link-hover-border: $grey-light !default;

$link-focus: $grey-darker !default;
$link-focus-border: $blue !default;

$link-active: $grey-darker !default;
$link-active-border: $grey-dark !default;

// megical colors

/* Abort/NA/Skip/Correct/OK */
$checklistItemValueUnknown: $black;
$checklistItemValueCheck: $primary;
$checklistItemValueOk: $info;
$checklistItemValueCorrect: $success;
$checklistItemValueSkip: $warning;
$checklistItemValueNa: $grey;
$checklistItemValueAbort: $danger;

/*  Q/S/H  */
$severityQuality: $primary;
$severitySaved: $warning;
$severityHarm: $danger;

/*  O/D */
$occurrenceOpen: #8dd4ff;
$occurrenceDefined: #ffaaaa;
