
// 1. Import the initial variables
@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/functions.sass";
@import "./_colors.sass";

$family-sans-serif: 'Inter UI', BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

// $navbar-background-color: $primary

$navbar-breakpoint: $tablet - 1px;
// $box-shadow: 0 0.0625rem 0.0625rem*2 0.0625rem #e6e6e6a8
// $box-shadow: 0 0.0325em 0.25em 0.0375rem #e8e8e8
// $box-shadow: 0 0.025rem 0.1rem 0.0375rem #e8e8e8 !important
$box-shadow: 0 0.1rem 0.25rem 0.0375rem #e8e8e8;
// $box-shadow: 0 0.8px 1px 0.8px rgba(216, 216, 216, 0.8)
$box-radius: 4px;
